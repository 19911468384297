.jack-header.navbar{
    position: fixed;
    width: 100%;
    padding: 0px;
    top: -100px;
    z-index: 99999999;
    background-color: rgba(1, 1, 1,  1);
    transition: top 0.6s;
    @media (max-width: 450px){
        margin-top: 0px;
    }

    &.visible{
        top: 0px;
    }
    @media (max-width: 450px){
        position: fixed;
    }
   
    @media (max-width: 997px){
        right: 0px;
    }
    .navbar-brand{
        color: white;
        @media (max-height: 752px) and (min-width: 767px){
            padding-bottom: 0px;
        }
    }
    .header-logo{
        padding-right: 70px;
        height: 90px;
        width: 150px;
        cursor: pointer;
        @media (max-height: 752px) and (min-width: 767px){
            height: 75px;
        }
    }
    .nav-link{
        color: white;
        font-family: Poppins-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.07em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 10px 20px !important;
        @media (max-width: 1280px){
            font-size: 12px;
        }
        @media (max-height: 752px) and (min-width: 767px){
            font-size: 12px;
        }
        &:hover{
            color: white;  
        }
    }
    .nav-link.active{
        color: white !important;  
    }
    .navbar-toggler-icon{
        background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/menu_icon.svg');
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    @media (max-width: 992px){
        .social-icons-nav.navbar-nav{
            display: flex !important;
            flex-grow: 1;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 10px;
            .nav-link{
                padding: 0px 5px !important;
            }
        }
    }
    @media (max-width: 400px){
        .navbar-brand{
            width: 30%;
        }

        .social-icons-nav.navbar-nav{
            padding-right: 10px;
            width: 30%;
        }
    }
}
.header-transparent{
    height: 50px;
    background: transparent;
    width: 100%;
    z-index: 99999;
    position: fixed;
}