.trade-assets-section{
    background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/TradeAssetsBG.svg');
    height: calc(100vh);
    position: relative;
    scroll-snap-align: end;
    color: white;
    position: relative;
    @media(max-width: 770px){
        height: auto;
    }
    .trade-assets-video{
        width: 100%;
        height: 100%;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -999;
        }
    }
    .trade-assets-caption{
        position: absolute;
        justify-content: space-around;
        text-align: center;
        left: 0px;
        right: 0px;
        align-items: center;
        padding: 15px;
        bottom: 0px;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        opacity: 0.75;
        @media (max-width: 576px){
            display: block !important;
        }
        h6{
            text-align: left;
        }
        img{
            position: absolute;
            width: 115px;
        }
        .top{
            top: 5px;
            right: 5px;
        }
        .bottom{
            bottom: 5px;
            left: 5px;
        }
        .line{
            bottom: 5px;
            right: 5px;
        }
        p{
            font-family: Kollektif;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: 0.07em;
            width: 100%;
            max-width: 46%;
            margin: 0px;
            text-align: left;
            min-height: 120px;
            @media (max-width: 576px){
                font-size: 14px;
                line-height: 20px;
                max-width: 100%;
                min-height: 60px;
            }
        }
    }

     ////CSS for desktop
     .trade-assets-desktop{
        background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/Back_Arrow_04.png');
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 15% 100%;
        background-position: 32.3% 100%;
        padding-top: 100px;
        position: relative;
        @media(max-height: 800px){
            background-size: 10.25% 100%;
            background-position: 39.1% 100%;
        }
        .number{
            position: absolute;
            left: 12%;
            top: 6%;
            width: 130px;
            @media(max-height: 800px){
                left: 18%;
                top: 6%;
                width: 100px;
            }
        }
       
       
        .inner-container{
            height: 100%;
            display: flex;
            .desktop-caption{
                height: 100%;
                display: flex;
                align-items: center;
                width: 35%;
                height: 100%;
                justify-content: flex-end;
                @media(max-height: 800px){
                    width: 40%;
                }
                .caption-inner{
                    width: 100%;
                    max-width: 70%;
                }
                h6{
                    font-size: 40px;
                    text-align: left;
                    @media(max-height: 800px){
                        font-size: 40px;
                    }
                }
                p{
                    font-family: Kollektif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0.07em;
                    text-align: left;
                    width: 100%;
                    max-width: 100%;
                    margin: 0px;
                    padding-top: 30px;
                    min-height: 150px;
                    @media(max-height: 800px){
                        font-size: 16px;
                        line-height: 25px;
                        min-height: 130px;
                    }
                }
                .bottom-arrow-div{
                    img{
                        width: 40px;
                        margin-top: 40px;
                        margin-bottom: 10px;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                    }
                }
                .caption-inner{
                    position: relative;
                    width: 100%;
                    margin-top: 70px;
                    z-index: 1;
                    @media(max-height: 800px){
                        width: 94%;
                        margin-left: 70px;
                    }
                   .number_img{
                    position: absolute;
                    top: -47%;
                    right: 0%;
                    height: 70%;
                    z-index: -1;
                    @media(max-height: 800px){
                        top: -37%;
                        right: 0%;
                        height: 57%;
                    }
                   }
                }
            }
            .frame-container{
                width: 65%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media(max-height: 800px){
                    width: 60%;
                }
                .video-upper-container{
                    width: 100%;
                    height: 480px;
                    @media(max-height : 800px){
                        height: 350px;
                    }
                    .video-container{
                        width: 95%;
                        height: 100%;
                        // margin-left: 30px;
                        display: flex;
                        justify-content: flex-start;
                        position: relative;
                        &:before{
                            content: "";
                            width: 102%;
                            height: 104%;
                            background: transparent;
                            border: 3px solid white;
                            position: absolute;
                            right: 0%;
                            top: -14%;
                            z-index: 0;
                        }
                        .trade-assets-gif{
                            width: 90%;
                            height: 95%;
                            object-fit: cover;
                            @media(max-height : 800px){
                                height: 87%;
                            }
                        }
                        .frame{
                            position: absolute;
                            width: 105%;
                            height: 110%;
                            left: -56px;
                            top: -56px;
                            z-index: 9999;
                            @media(max-height : 770px){
                                left: -20px;
                                top: -25px;
                            }
                        }
                       
                        
                    }
                    // .video-container{
                    //     width: 95%;
                    //     height: 100%;
                    //     // margin-left: 30px;
                    //     display: flex;
                    //     justify-content: flex-start;
                    //     position: relative;
                    //     &:before{
                    //         content: "";
                    //         width: 102%;
                    //         height: 104%;
                    //         background: transparent;
                    //         border: 3px solid white;
                    //         position: absolute;
                    //         top: -6%;
                    //         left: -5%;
                    //         z-index: 0;
                    //         @media(max-height : 800px){
                    //             left: -5%;
                    //             top: -7%;
                    //         }
                    //     }
                    //     .trade-assets-gif{
                    //         width: 100%;
                    //         height: 100%;
                    //         object-fit: cover;
                    //     }
                    //     .frame{
                    //         position: absolute;
                    //         width: 105%;
                    //         height: 110%;
                    //         left: -15px;
                    //         top: -16px;
                    //         z-index: 999;
                    //     }
                       
                        
                    // }
                }
              
            }
        }
    }

    .trade-assets-mobile{
        background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen9/Back_Arrow_04.png');
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 50% 100%;
        background-position: -70% 100%;
        position: relative;
        .number{
            position: absolute;
            left: 50%;
            top: 5%;
            width: 130px;
            @media(max-height: 800px){
                left: 50%;
                top: 6%;
                width: 100px;
            }
        }
       
        .mobile-caption{
            h6{
                font-size: 26px;
            }
            .Typewriter{
                font-family: Kollektif;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.07em;
                text-align: center;
                width: 100%;
                max-width: 74%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                min-height: 80px;
                font-size: 16px;
                line-height: 25px;
            }
        }
        .inner-container{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .mobile-caption{
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 70px;
                .caption-inner{
                    position: relative;
                    width: 100%;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 50px;
                   .number_img{
                    width: 100px;
                   }
                   .jack-image{
                    z-index: -1;

                   }
                   .mobile-caption-message{
                        text-align: center;
                        width: 80%;
                    }
                }
            }
            .frame-container{
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .video-upper-container{
                    width: 100%;
                    height: 240px;
                    .video-container{
                        width: 95%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        position: relative;
                        &:before{
                            content: "";
                            width: 102%;
                            height: 104%;
                            background: transparent;
                            border: 3px solid white;
                            position: absolute;
                            right: 0%;
                            top: -14%;
                            z-index: 0;
                        }
                        video{
                            width: 90%;
                            height: 87%;
                            object-fit: cover;
                            @media(max-height : 800px){
                                height: 87%;
                            }
                        }
                        img{
                            width: 100%;
                        }
                        .frame{
                            position: absolute;
                            width: 105%;
                            height: 110%;
                            left: -56px;
                            top: -56px;
                            z-index: 9999;
                            @media(max-width : 770px){
                                left: -20px;
                                top: -25px;
                            }
                        }
                       
                        
                    }
                }
              
            }
            .bottom-arrow-div{
                img{
                    width: 40px;
                    position: absolute;
                    bottom: -70px;
                    right: 20%;
                }
            }
        }
    }
}
