.tech-and-game-section{
    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/tech_and_game_bg.jpg');
    background-repeat: no-repeat;
    position: relative;
    color: white;
    height: calc(100vh);
    scroll-snap-align: end;
    background-size: 100% 101%;
    padding-top: 20px;
    padding-bottom: 20px;
   
    @media (max-height : 752px)  and (min-width: 767px){
        padding-top: 0px;
        padding-bottom: 0px;
    }

    @media(max-width : 767px){
        padding-top: 50px ;
    }

    .heading-mobile{
        background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Technology Encome Box.png");
        background-repeat: no-repeat;
        background-size: 85% 100%;
        background-position: center;
        margin: 0px auto;
        padding: 20px 10px;
        h6{
            font-size: 25px;
        }
    }
   
    @media (max-width: 450px){
        height: auto;
    }

    
    .tech-and-game-caption{
        padding-top: 10px;
        p{
            font-family: 'Kollektif';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            padding: 10px 15px;
            line-height: 25px;
            letter-spacing: 0.04em;
            color: #FFFFFF;
            text-align: center;
        }
    }
    .section-wrapper{
        display: table;
        height: 100%;
        padding: 10px 0px;
        @media (max-width: 900px){
            width: 100%;
            padding: 10px;
            max-width: 100%;
        }
        .section-wrapper-inner{
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            height: 100%;
            padding: 0px;
           
        }
    }
    .row.boxes-row{
        height: auto;
        margin-top: 30px !important;
        &:first-child{
            margin-top: 0px !important;
        }
        @media (max-width: 767px){
            margin-top: 0px !important;
        }
    }
    .middle-row{
        height: auto;
    }
   
    .blockchain-box{
        .box-text{
        background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Blockchain Box.png");
        }
    }
    .native-token-box{
        .box-text{
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Native Tokens Box.png");
            margin : 0 auto;
        }
    }
    .governance-box{
        .box-text{
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Governance Box.png");
            margin-left : auto;
        }
    }
    .augmented-reality-box{
        .box-text{
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Augmented Reality Box.png");
        }
    }
    .player-generated-box{
        .box-text{
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Player\ Generated\ Content\ Box.png");
            margin: 0 auto;
        }
    }
    .creators-opp-box{
        .box-text{
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Creators\ Opportunity\ Box.png");
            margin-left : auto;
        }
    }
    .middle-box{
        background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Technology Encome Box.png");
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        margin: 20px auto;
        position: relative;
        height: 200px;
        width: 61.5%;
        justify-content: center;
        align-items: center;
        display: flex;
        @media (max-height: 752px) and (min-width: 767px){
            height: 120px;
            width: 45%;
        }
        h6{
            font-family: 'Blackpast';
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 109.8%;
            text-align: center;
            letter-spacing: 0.01em;
            text-transform: lowercase;
            color: #00DEFF;
            text-shadow: 0px 9px 20px rgba(122, 238, 255, 0.45);
            width: 100%;
            max-width: 70%;
            margin: 0 auto;
            @media (max-width: 1190px){
                font-size: 40px;
            }
            @media (max-height: 752px) and (min-width: 767px){
                font-size: 32px;
            }
        }
    }
    .box-col{
        position: relative;
        visibility: visible;
        opacity: 1;
        padding: 0px;

        @media (max-width: 767px){
            width: 100%;
        }
        .box-text{
            opacity: 0;
            visibility: hidden;
            width: 100%;
            max-width: 90%;
            text-align: left;
            padding: 30px 20px;
            padding-bottom: 50px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            height: 200px;
            
            @media (max-width: 900px){
                height: 300px;
            }
            @media (max-height: 752px) and (min-width: 767px){
                height: 160px;
                padding: 20px 20px;
            }
            @media (max-width: 767px){
                width: 100%;
                padding: 20px;
                margin: 10px auto;
                transition: none;
                opacity: 1;
                visibility: visible;
                height: auto;
            }
            h6{
                font-family: 'Blackpast';
                font-style: normal;
                font-weight: 400;
                font-size: 23px;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-transform: lowercase;
                @media (max-width: 1190px){
                    font-size: 20px;
                }
                @media (max-height: 752px) and (min-width: 767px){
                   font-size: 18px;
                }
            }
            p{
                font-family: 'Kollektif';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                @media (max-width: 1190px){
                    font-size: 14px;
                    line-height: 20px;
                }
                @media (max-height: 752px) and (min-width: 767px){
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }
        .line-div{
            position: absolute;
            transition: opacity 1s ease, border-color 1s ease, height 1s ease;
            opacity: 0.1;
        }
        
        .blockchain-line-img{
            width: 23%;
            height: 0;
            bottom: -57%;
            left: 40%;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            @media (max-width: 900px){
                width: 20%;
                height: 0;
                bottom: -41%;
            }

            @media(max-height : 752px)and (min-width: 767px){
                width: 45.1%;
                bottom: -52%;
            }
       
            .small-circle{
                top: -10px;
                left: -7px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 1;
                transition: opacity 1s ease;
            }
            .big-circle{
                bottom: -11px;
                right: -17px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px) and (min-width: 767px){
                    width: 16px;
                    right: -16px;
                    bottom: -10px;
                }
            }
        }
        .native-token-line-img{
            width: 8%;
            height: 0%;
            bottom: 0%;
            border-left: 3px solid transparent;
            border-top: 3px solid transparent;
            right: 97%;
            @media (max-width: 900px){
                width: 6%;
                right: 99%;
            }
            @media(max-height : 752px) and (min-width: 767px){
                width: 8%;
                bottom: -15%;
                right: 97.5%;
            }
            .small-circle{
                top: -7px;
                right: -10px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 0;
                transition: opacity 4s ease;
                // transition-delay: 2s;
            }
            .big-circle{
                bottom: -20px;
                left: -12px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px) and (min-width: 767px){
                    width: 16px;
                    left: -9px;
                    bottom: -16px;
                }
            }
        }
        .governance-line-img{
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            width: 24%;
            height: 0;
            bottom: -57%;
            right: 40%;

            @media (max-width: 900px){
                width: 22%;
                bottom: -41%;
                right: 40%;
            }
            @media (max-height: 752px)  and (min-width: 767px){
                width: 45.7%;
                bottom: -52%;
            }
            .small-circle{
                top: -10px;
                right: -7px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 0;
                transition: opacity 2s ease;
            }
            .big-circle{
                bottom: -11px;
                left: -17px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px) and (min-width: 767px){
                    width: 16px;
                    left: -16px;
                    bottom: -10px;
                }
            }
        }
        .augumented-line-img{
            width: 23%;
            height: 0;
            border-left: 3px solid transparent;
            border-top: 3px solid transparent;
            top: -58%;
            left: 40%;
            @media (max-width: 900px){
                width: 20%;
                top: -41%;
            }
            @media(max-height : 752px) and (min-width: 767px){
                width: 45.1%;
                top: -52%;
                left: 40%;
            }
           
            .small-circle{
                bottom: -10px;
                left: -7px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 0;
                transition: opacity 2s ease;
            }
            .big-circle{
                top: -11px;
                right: -17px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px) and (min-width: 767px){
                    width: 16px;
                    right: -16px;
                    top: -9px;
                }
            }
        }
        .player-line-img{
            width: 8%;
            height: 0;
            border-bottom: 3px solid transparent;
            border-right: 3px solid transparent;
            top: -16%;
            left: 97%;
            @media (max-height: 752px) and (min-width: 767px){
                top: -22%;
            }
            @media (max-width: 900px){
                top: -10%;
            }
            .small-circle{
                bottom: -8px;
                left: -10px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 0;
                transition: opacity 2s ease;
            }
            .big-circle{
                top: -20px;
                right: -13px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px){
                    width: 16px;
                    right: -9.5px;
                    top: -16px;
                }
            }
        }
        .creators-opp-line-img{
            border-right: 3px solid transparent;
            border-top: 3px solid transparent;
            width: 23%;
            height: 0;
            top: -57%;
            left: 37%;
            @media (max-width: 900px){
                width: 21%;
                top: -41%;
                left: 39%;
            }
            @media (max-height: 752px) and (min-width: 767px){
                width: 45%;
                top: -52%;
                left: 14.8%;
            }
            .small-circle{
                bottom: -10px;
                right: -7px;
                position: absolute;
                height: 10px;
                background: transparent;
                opacity: 0;
                transition: opacity 2s ease;
            }
            .big-circle{
                top: -11px;
                left: -18px;
                position: absolute;
                background: transparent;
                opacity: 0;
                transition: opacity 0.5s ease;
                @media(max-height : 752px) and (min-width: 767px){
                    width: 16px;
                    left: -16px;
                    top: -10px;
                }
            }
        }
        
}
.tech-and-game-section.inView{
    .box-col{
        opacity: 1;
        visibility: visible;
    }
    .small-circle, .big-circle{
        opacity: 1 !important;
    }
    .blockchain-line-img{
        border-color: #7AEEFF !important;
        height: 55% !important;
        @media (max-width: 900px){
            height: 40% !important;
        }
        @media(max-height : 752px) and (min-width: 767px){
           height: 48% !important;
        }
    }
    .native-token-line-img{
        border-color: #7AEEFF !important;
        height: 52% !important;
        @media(max-height : 752px) and (min-width: 767px){
            height: 65% !important;
        }
    }
    .governance-line-img{
        height: 54% !important;
        border-color: #7AEEFF !important;
        @media (max-width: 900px){
           height: 39% !important;
        }
        @media (max-height: 752px) and (min-width: 767px){
            height: 48% !important;
        }
    }
    .augumented-line-img{
        height: 55% !important;
        border-color: #7AEEFF !important;
        @media (max-width: 900px){
            height: 40% !important;
        }
        @media(max-height : 752px) and (min-width: 767px){
            height: 48% !important;
        }
    }
    .player-line-img{
        height: 80% !important;
        border-color: #7AEEFF !important;
         @media (max-width: 900px){
            height: 58% !important;
        }
        @media (max-height: 752px) and (min-width: 767px){
            height: 73% !important;
        }
    }
    .creators-opp-line-img{
        border-color: #7AEEFF !important;
        height: 54% !important;
        @media (max-width: 900px){
           height:40% !important
        }
        @media (max-height: 752px) and (min-width: 767px){
            height:48% !important
        }
    }
    .line-div{
        opacity: 0.8 !important;
    }
    .box-text{
        transition-property: opacity, visibility;
        transition-duration: 2s, 2s;
        transition-delay: 1s, 1s;
        opacity: 1;
        visibility: visible;
        @media (max-width: 767px){
            transition: none;
           
        }
    }
}
.tech-and-game-section::-webkit-scrollbar{
    display: none;
}