.banner-section{
    height: calc(100vh);
    // margin-top: 100px;
    position: relative;
    scroll-snap-align: end;
    color: white;
    // padding-top: 100px;
    @media (max-width : 600px){
        padding-top: 0px;
    }
    .button-row{
        font-family: 'Kollektif';
        div:first-child{
            text-align: right;
            position: relative;
            @media (max-width:767px){
                text-align: center;
            }

            img{
                position: absolute;
                top: -9px;
                left: 22%;
                width: 22px;
                @media (max-width:767px){
                    left: 26%;
                }
                @media (max-width:767px){
                    left: 20%;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    top: -8px;
                    left: 28%;
                }
            }
            button{
                background: #01014C;
                border: 5px solid #12DEF2;
                box-shadow: 0px 14px 19px -5px rgba(18, 222, 242, 0.69), inset 0px 10px 12px rgba(18, 222, 242, 0.8);
                border-radius: 37px;
                padding: 10px 32px;
                a{
                    color: #01014C !important;
                    text-decoration: none !important;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    padding: 6px 25px;
                }
            }
        }
        div:nth-child(2){
            text-align: left;
            @media (max-width:767px){
                text-align: center;
            }

            button{
                border: 5px solid #01014C;
                padding: 10px 32px;
                background: #01014C;
                border-radius: 37px;
                @media (max-height : 752px)  and (min-width: 767px){
                    padding: 6px 25px;
                }
            }
        }
    }
    .banner-desktop-container{
            height: calc(100vh);
           
            .frame-container{
                img{
                    position: absolute;
                    width: 100%;
                    height:calc(97vh);
                    object-fit: cover;
                    z-index: 0;
                    top : 10%
                }
            }
            .video-upper-container-watch{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: calc(96vh);
                .video-container-watch{
                    padding: 0px;
                    margin: 0px;
                    width: 73%;
                    object-fit: cover;
                    position: relative;
                    margin-top: 3%;
                    .heading{
                        bottom: calc(-80px);
                        position: absolute;
                        width: 100%;
                        z-index: 9999999;
                        .btn-watch-image{
                            width:250px;
                            cursor: pointer;
                            @media (max-width:576px){
                                width:150px;
                            }
                        }
                        img{
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .button-row{
                        font-family: 'Kollektif';
                        position: absolute;
                        bottom: 4%;
                        z-index: 9999999;
                        margin: 0 auto;
                        div:nth-child(2){
                            text-align: left;
                            position: relative;
                            @media (max-width:767px){
                                text-align: center;
                            }
                
                            img{
                                position: absolute;
                                top: -9px;
                                left: -2px;
                                width: 22px;
                                // @media (max-width:767px){
                                //     left: 26%;
                                // }
                                // @media (max-width:767px){
                                //     left: 20%;
                                // }
                                @media (max-height : 752px)  and (min-width: 767px){
                                    top: -8px;
                                }
                            }
                            button{
                                background-color: white;
                                color: #01014C;
                                box-shadow: none;
                                border: 5px solid #12DEF2;
                                border-radius: 37px;
                                padding: 10px 32px;
                                @media (max-height : 752px)  and (min-width: 767px){
                                    padding: 6px 25px;
                                }
                            }
                        }
                        div:first-child{
                            text-align: right;
                            @media (max-width:767px){
                                text-align: center;
                            }
                
                            button{
                                background-color: white;
                                color: #01014C;
                                box-shadow: none;
                                border: 5px solid white;
                                border-radius: 37px;
                                @media (max-height : 752px)  and (min-width: 767px){
                                    padding: 6px 25px;
                                }
                            }
                        }
                    }
                    // .button-row{
                    //     position: absolute;
                    //     bottom: 0%;
                    //     z-index: 9999999;
                    //     button{
                    //         background-color: white;
                    //         color: #01014C;
                    //         box-shadow: none;
                    //     }
                    //     div:first-child{
                    //         img{
                    //             left: 54%;
                    //         }
                    //     }
                    //     div:nth-child(2){
                    //         button{
                    //             border: 5px solid white;
                    //         }
                    //     }
                    // }
                    video{
                        padding:0px;
                        margin:0px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .video-upper-container{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                z-index: 1;
                .video-container{
                    padding: 0px;
                    margin: 0px;
                    width: 73%;
                    object-fit: cover;
                    position: relative;
                    .heading{
                        bottom: 30%;
                        position: absolute;
                        width: 100%;
                        z-index: 99;
                        img{
                            display: block;
                            margin: 0 auto;
                            width: 50%;
                        }
                    }
                    .button-row{
                        font-family: 'Kollektif';
                        position: absolute;
                        bottom: 4%;
                        z-index: 99;
                        margin: 0 auto;
                        div:nth-child(2){
                            text-align: left;
                            position: relative;
                            @media (max-width:767px){
                                text-align: center;
                            }
                
                            img{
                                position: absolute;
                                top: -9px;
                                left: -2px;
                                width: 22px;
                                // @media (max-width:767px){
                                //     left: 26%;
                                // }
                                // @media (max-width:767px){
                                //     left: 20%;
                                // }
                                @media (max-height : 752px)  and (min-width: 767px){
                                    top: -8px;
                                }
                            }
                            button{
                                background-color: white;
                                color: #01014C;
                                box-shadow: none;
                                border: 5px solid #12DEF2;
                                border-radius: 37px;
                                padding: 10px 32px;
                                @media (max-height : 752px)  and (min-width: 767px){
                                    padding: 6px 25px;
                                }
                            }
                        }
                        div:first-child{
                            text-align: right;
                            @media (max-width:767px){
                                text-align: center;
                            }
                
                            button{
                                background-color: white;
                                color: #01014C;
                                box-shadow: none;
                                border: 5px solid white;
                                border-radius: 37px;
                                @media (max-height : 752px)  and (min-width: 767px){
                                    padding: 6px 25px;
                                }
                            }
                        }
                    }
                    // .button-row{
                    //     position: absolute;
                    //     bottom: 0%;
                    //     z-index: 9999999;
                    //     button{
                    //         background-color: white;
                    //         color: #01014C;
                    //         box-shadow: none;
                    //     }
                    //     div:first-child{
                    //         img{
                    //             left: 54%;
                    //         }
                    //     }
                    //     div:nth-child(2){
                    //         button{
                    //             border: 5px solid white;
                    //         }
                    //     }
                    // }
                    video{
                        padding:0px;
                        margin:0px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .bottom-caption{
                width: 100%;
                margin: 0px auto;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999999999999999999;
                position: absolute;
                background: #000075;
                height: 60px;
                bottom: 0px;
                // font-size: 20px;
                // line-height: 36px;
                // letter-spacing: 0.07em;
                p{
                    font-family: 'Kollektif';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    color: white;
                    margin: 0px;
                    @media (max-width: 1280px){
                        font-size: 15px;
                    }
                    @media (max-width: 576px){
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
           
            // background-image: url('https://assets-jack.s3.ap-south-1.amazonaws.com/assets/images/screen1/top_frame_small.png');
            // background-repeat: no-repeat;
            // background-size: cover;
            // background-position: 100% 100%;
    }
    .banner-video{
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // width: 100%;
        // height: 100%; 
        // overflow: hidden;

        padding:0px;
        margin:0px;
        width:100%;
        height:100vh;
        object-fit: cover;
        
        
        video{
            // object-fit: cover;
            padding:0px;
            margin:0px;
            width:100%;
            height:100vh;
            object-fit: cover;

            // min-width: 100%; 
            // min-height: 100%; 
            // width: auto;
            // height: auto;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%,-50%);
        }

        .mobile-banner-img{
            width: 100%;
            height: 100%;
            margin-top: 90px;
            @media (max-height: 620px){

                height: 120%;
                width: 100%;
                
            }
        }
    }
    .banner-caption{
        position: absolute; 
        left: 0; 
        right: 0; 
        width: 100%;
        bottom: 0px;
        padding-top: 20px;
        height: auto;
        .banner-caption-inner{
            max-width: 600px;
            margin-left: auto; 
            margin-right: auto;
        }
        .video-container{
            .heading{
                bottom: 30%;
                position: absolute;
                width: 100%;
                z-index: 99;
                img{
                    display: block;
                    margin: 0 auto;
                    width: 50%;
                }
            }
            .button-row{
                font-family: 'Kollektif';
                margin: 0 auto;
                div:nth-child(2){
                    text-align: left;
                    position: relative;
                    @media (max-width:767px){
                        text-align: center;
                    }
        
                    img{
                        position: absolute;
                        top: -9px;
                        left: -2px;
                        width: 22px;
                        // @media (max-width:767px){
                        //     left: 26%;
                        // }
                        // @media (max-width:767px){
                        //     left: 20%;
                        // }
                        @media (max-height : 752px)  and (min-width: 767px){
                            top: -8px;
                        }
                    }
                    button{
                        background-color: white;
                        color: #01014C;
                        box-shadow: none;
                        border: 0px solid #12DEF2;
                        border-radius: 37px;
                        padding: 10px 32px;
                        @media (max-height : 752px)  and (min-width: 767px){
                            padding: 6px 25px;
                        }
                    }
                }
                div:first-child{
                    text-align: right;
                    @media (max-width:767px){
                        text-align: center;
                    }
        
                    button{
                        background-color: white;
                        color: #01014C;
                        box-shadow: none;
                        border: 5px solid #12DEF2;
                        border-radius: 37px;
                        @media (max-height : 752px)  and (min-width: 767px){
                            padding: 6px 25px;
                        }
                    }
                }
            }
        }
        h1{
            font-family: 'Blackpast';
            font-style: normal;
            font-weight: 400;
            font-size: 55px;
            line-height: 109.8%;
            text-align: center;
            letter-spacing: 0.01em;
            text-transform: lowercase;
            color: #FFF;
            text-shadow: 0px 9px 20px rgba(122, 238, 255, 0.45);
            @media (max-width: 1280px){
                font-size: 45px;
              }
            @media (max-width: 576px){
                font-size: 35px;
            }
            @media (max-height : 752px)  and (min-width: 767px){
                font-size: 45px;
            }
        }
       
        .vid-text{
            font-family: 'Kollektif';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: white;
            @media (max-width: 1280px){
                font-size: 15px;
            }
            @media (max-width: 576px){
                font-size: 14px;
                line-height: 20px;
            }
        }

        .video-upper-container-watch{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: calc(56vh);
            .video-container-watch{
                padding: 0px;
                margin: 0px;
                width: 73%;
                object-fit: cover;
                position: relative;
                margin-top: 3%;
                .heading{
                    bottom: calc(60px);
                    position: absolute;
                    width: 100%;
                    z-index: 9999999;
                    @media (max-height: 667px){
                       bottom: calc(-90px);
                    }
                    .btn-watch-image{
                        width:150px;
                    }
                    img{
                        display: block;
                        margin: 0 auto;
                    }
                }
                .button-row{
                    font-family: 'Kollektif';
                    position: absolute;
                    bottom: 4%;
                    z-index: 9999999;
                    margin: 0 auto;
                    div:nth-child(2){
                        text-align: left;
                        position: relative;
                        @media (max-width:767px){
                            text-align: center;
                        }
            
                        img{
                            position: absolute;
                            top: -9px;
                            left: -2px;
                            width: 22px;
                            // @media (max-width:767px){
                            //     left: 26%;
                            // }
                            // @media (max-width:767px){
                            //     left: 20%;
                            // }
                            @media (max-height : 752px)  and (min-width: 767px){
                                top: -8px;
                            }
                        }
                        button{
                            background-color: white;
                            color: #01014C;
                            box-shadow: none;
                            border: 5px solid #12DEF2;
                            border-radius: 37px;
                            padding: 10px 32px;
                            @media (max-height : 752px)  and (min-width: 767px){
                                padding: 6px 25px;
                            }
                        }
                    }
                    div:first-child{
                        text-align: right;
                        @media (max-width:767px){
                            text-align: center;
                        }
            
                        button{
                            background-color: white;
                            color: #01014C;
                            box-shadow: none;
                            border: 5px solid white;
                            border-radius: 37px;
                            @media (max-height : 752px)  and (min-width: 767px){
                                padding: 6px 25px;
                            }
                        }
                    }
                }
                // .button-row{
                //     position: absolute;
                //     bottom: 0%;
                //     z-index: 9999999;
                //     button{
                //         background-color: white;
                //         color: #01014C;
                //         box-shadow: none;
                //     }
                //     div:first-child{
                //         img{
                //             left: 54%;
                //         }
                //     }
                //     div:nth-child(2){
                //         button{
                //             border: 5px solid white;
                //         }
                //     }
                // }
                video{
                    padding:0px;
                    margin:0px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    #headerPopup{
        width:75%;
        margin:0 auto;
        position: fixed;
        top: 25%;
        z-index: 99999999999;
      }
      
    #headerPopup iframe{
        width:100%;
        margin:0 auto;
      }
   
}