.next-level-gaming-section{
    background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen5/evolveBG.jpg');
    background-repeat: no-repeat;
    position: relative;
    color: white;
    height: calc(100vh);
    scroll-snap-align: end;
    background-size: 100% 100%;
    padding-top: 20px;
    // padding-top: 100px;
        @media (max-height : 752px)  and (min-width: 767px){
        padding-top: 30px;
        padding-bottom: 20px;
    }
    @media (max-width: 767px){
       height: auto;
    }
    
    .section-wrapper{
        height: 100%;
        display: table;
        .section-wrapper-inner{
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            height: 100%;
            width: 100vw;
            .section-scroll-container{
                // max-height: calc(100vh - 160px);
                width: 100%;
                // overflow-y: auto;
                overflow-x: hidden;
                padding-bottom: 30px;
            }
        }   
    }
    .row{
        height: auto;
    }
    .middle-row{
        min-height: 250px;
        @media (max-height : 752px)  and (min-width: 767px){
            min-height: 120px;
        }
    }
    .middle-box{
        background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen5/Middle_Box.svg");
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        margin: 20px auto;
        position: relative;
        display: table;
        width: 45%;
        height: 200px;
        @media (max-height : 752px)  and (min-width: 767px){
            height: 120px;
        }
        .middle-box-caption{
            padding: 20px;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            // @media (max-height: 750px){
            //     padding: 15px;
            // }
            
            h6{
                padding-bottom : 30px;
                font-family: 'Blackpast';
                font-style: normal;
                font-weight: 400;
                font-size: 50px;
                line-height: 109.8%;
                text-align: center;
                letter-spacing: 0.01em;
                text-transform: lowercase;
                color: #00DEFF;
                text-shadow: 0px 9px 20px rgba(122, 238, 255, 0.45);
                margin: 0 auto;
                @media (max-width: 1150px){
                    font-size: 40px;
                }
                @media (max-width: 800px){
                    font-size: 30px;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 35px;
                }
            }
            p{
                font-family: 'Kollektif';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                @media (max-width: 1150px){
                    font-size: 16px;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 16px;
                }
            }
        }
    }
    .middle-box-mobile{
        .middle-box-caption{
            padding: 20px;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;            
            h6{
                padding-bottom : 30px;
                font-family: 'Blackpast';
                font-style: normal;
                font-weight: 400;
                font-size: 50px;
                line-height: 109.8%;
                text-align: center;
                letter-spacing: 0.01em;
                text-transform: lowercase;
                color: #00DEFF;
                text-shadow: 0px 9px 20px rgba(122, 238, 255, 0.45);
                margin: 0 auto;
                @media (max-width: 1150px){
                    font-size: 40px;
                }
                @media (max-width: 800px){
                    font-size: 30px;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 35px;
                }
            }
            p{
                font-family: 'Kollektif';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                @media (max-width: 1150px){
                    font-size: 16px;
                }
                @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 16px;
                }
            }
        }
    }
    .box-col{
        position: relative;
        .box-text{
            text-align: left;
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 35px;
            padding-right: 20px;
            height: 180px;
            width: 100%;
            max-width: 80%;
            background: url("https://d14utwz37q7qa4.cloudfront.net/assets/images/screen10/Blockchain Box.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            visibility: hidden;
            @media (max-width: 800px){
               padding: 15px;
            }
            @media (max-width: 767px){
                max-width: 100%;
                margin: 20px auto;
                opacity: 1;
                visibility: visible;
            }
            @media (max-height : 752px)  and (min-width: 767px){
                height: 150px;
            }
            h6{
                font-family: 'Blackpast';
                font-style: normal;
                font-weight: 400;
                font-size: 23px;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-transform: lowercase;
                @media (max-width: 800px){
                    font-size: 20px;
                 }
                 @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 18px;
                }
            }
            p{
                padding-top: 30px;
                font-family: 'Kollektif';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                @media (max-width: 800px){
                    padding-top: 0px;
                 }
                 @media (max-height : 752px)  and (min-width: 767px){
                    font-size: 14px;
                    line-height: 20px;
                    padding-top: 10px;
                }
            }
        }
        
    }

   

    .limitations-box{
        .box-text{
            // @media (max-width: 1024px){
            //     margin-left: 0px;
            // }
        }
    }
    .player-ownership-box{
        .box-text{
            // @media (max-width: 1024px){
            //     margin-right: 10px;
            // }
        }
       
    }

    .immersive-technology-box{
        .box-text{
            // @media (max-width: 1024px){
            //     margin-right: 0px;
            // }
        }
    }
    .never-ending-box{
        .box-text{
            // @media (max-width: 1024px){
            //     margin-left: 10px;
            // }
        }
    }

    .line-div{
        position: absolute;
        transition: opacity 1s ease, border-color 1s ease, height 1s ease;
        opacity: 0.1;
    }
    
    .limitations-line-img{
        width: 19%;
        height: 0%;
        bottom: -77%;
        left: 38%;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;

        @media (max-width: 800px){
            left: 35%;
        }
        @media (max-height : 752px)  and (min-width: 767px){
            width: 27%;
            bottom: -72%;
            left: 38%;
        }

       
        .small-circle{
            top: -10px;
            left: -7px;
            position: absolute;
            height: 10px;
            background: transparent;
            opacity: 0;
            transition: opacity 0.8s ease;
        }
        .big-circle{
            bottom: -10px;
            right: -20px;
            position: absolute;
            background: transparent;
            opacity: 0;
            transition: opacity 0.8s ease;
            z-index: 9;
            @media (max-height : 752px)  and (min-width: 767px){
                bottom: -8px;
                right: -16px;
                width: 16px;
            }
        }
    }
    .immersive-technology-line-img{
        width: 22%;
        height: 0;
        border-left: 3px solid transparent;
        border-top: 3px solid transparent;
        bottom: -1%;
        left: -2%;
        @media (max-height : 752px)  and (min-width: 767px){
            bottom: -3%;
        }
        .small-circle{
            top: -7px;
            right: -10px;
            position: absolute;
            height: 10px;
            background: transparent;
            opacity: 0;
            transition: opacity 2s ease;
        }
        .big-circle{
            bottom: -19px;
            left: -11px;
            position: absolute;
            background: transparent;
            opacity: 0;
            transition: opacity 0.5s ease;
            @media (max-height : 752px)  and (min-width: 767px){
                bottom: -16px;
                left: -10px;
                width: 16px;
            }
        }
    }
    .never-ending-line-img{
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        width: 19%;
        height: 0;
        left: 80%;
        top: -11px;
        @media (max-height : 752px)  and (min-width: 767px){
            top: -4px;
            left: 80.2%;
        }
        .small-circle{
            bottom: -7px;
            left: -10px;
            position: absolute;
            height: 10px;
            background: transparent;
            opacity: 0;
            transition: opacity 2s ease;
        }
        .big-circle{
            top: -19px;
            right: -11px;
            position: absolute;
            background: transparent;
            opacity: 0;
            transition: opacity 0.5s ease;
            @media (max-height : 752px)  and (min-width: 767px){
                top: -16px;
                right: -9.5px;
                width: 16px;
            }
        }
    }

    .player-ownership-line-img{
        border-right: 3px solid transparent;
        border-top: 3px solid transparent;
        width: 26.5%;
        height: 0;
        top: -70%;
        right: 31%;

         @media (max-width: 800px){
            right: 37%;
        }
        @media (max-height : 752px)  and (min-width: 767px){
            top: -61%;
            right: 39%;
        }
        .small-circle{
            bottom: -10px;
            right: -7px;
            position: absolute;
            height: 10px;
            background: transparent;
            opacity: 0;
            transition: opacity 2s ease;
        }
        .big-circle{
            top: -10px;
            left: -20px;
            position: absolute;
            background: transparent;
            opacity: 0;
            transition: opacity 0.5s ease;
            @media (max-height : 752px)  and (min-width: 767px){
                top: -9px;
                left: -16px;
                width: 16px;
            }
        }
    }
}
.next-level-gaming-section.inView{
    .small-circle, .big-circle{
        opacity: 1 !important;
    }
    .limitations-line-img{
        border-color: #7AEEFF !important;
        height: 74% !important;
        @media (max-height : 752px)  and (min-width: 767px){
            height: 68% !important;
        }
    }
    .immersive-technology-line-img{
        border-color: #7AEEFF !important;
        height: 55% !important;
    }
    .never-ending-line-img{
        height: 55% !important;
        border-color: #7AEEFF !important;
    }
    .player-ownership-line-img{
        border-color: #7AEEFF !important;
        height: 64% !important;
        @media (max-height : 752px)  and (min-width: 767px){
            height: 54% !important;
        }
    }
    .line-div{
        opacity: 1 !important;
    }
    .box-text{
        transition-property: opacity, visibility;
        transition-duration: 2s, 2s;
        transition-delay: 1s, 1s;
        opacity: 1;
        visibility: visible;
        @media (max-width: 767px){
            transition: none;
        }
    }
}
.next-level-gaming-section .section-scroll-container::-webkit-scrollbar{
    display: none;
}