.develop-and-monetize-section{
    background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/landdevBG.svg');
    color: white;
    height: calc(100vh);
    scroll-snap-align: end;
    position: relative;
    @media(max-width: 770px){
        height: auto;
    }
    .right_top_border{
        z-index: 9;
        width: 30%;
        top: 0px;
        right: 0px;
    }
    .right_down_border{
        z-index: 999;
        width: 30%;
        bottom: 0px;
        right: 0px;
    }
    .left-section{
        background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen8/develop_and_monatize_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        height: 100%;
        bottom: 0px;
        width: 50%;
        display: table;
        @media (max-width: 600px){
            position: relative;
            width: 100%;
            height: auto;
        }
        .left-section-caption{
            padding: 10px 75px;
            display: table-cell;
            vertical-align: middle;
            @media (max-width: 525px){
                font-size: 14px;
                line-height: 20px;
                padding: 10px;
            }
            p{
            font-family: 'Kollektif';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.07em;
            margin-top: 50px !important;
            @media (max-width: 1280px){
                line-height: 20px;
                font-size: 15px;
            }
            
            @media (max-width: 600px){
                font-size: 14px;
                line-height: 20px;
            }
            }
        }
    }
    .right-section{
        display: flex;
        justify-content: flex-end;
        @media (max-width: 600px){
            padding: 0px;
        }
        video{
            width: 60%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 600px){
                width: 100%;
            }
        }
    }

    .develop-and-monetize-mob{
        width: 100%;
        height: 100%;
        .monetize-video{
            width: 100%;
            height: 100%;
            position: relative;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -999;
            }
        }
        .monetize-caption{
            position: absolute;
            bottom: 30px;
            left: 0px;
            right: 0px;
            align-items: center;
            padding: 15px;
            opacity: 0.75;
            display: block !important;
            h6{
                text-align: left;
                font-size: 30px;
            }
            img{
                position: absolute;
                width: 115px;
            }
            .top{
                top: 5px;
                right: 5px;
            }
            .bottom{
                bottom: 5px;
                left: 5px;
            }
            .line{
                bottom: 5px;
                right: 5px;
            }
            p{
                font-family: Kollektif;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.07em;
                width: 100%;
                margin: 0px;
                font-size: 14px;
                line-height: 20px;
                max-width: 100%;
                text-align: left;
                min-height: 80px;
                padding-bottom: 5px;
            }
        }
    }


     ////CSS for desktop
     .develop-land-desktop{
        background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen7/Back_Arrow.png');
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 15% 100%;
        background-position: 32.3% 100%;
        padding-top: 100px;
        position: relative;
        @media(max-height: 800px){
            background-size: 10.25% 100%;
            background-position: 39.1% 100%;
        }
        .number{
            position: absolute;
            left: 50%;
            top: 4%;
            width: 130px;
            @media(max-height: 800px){
                left: 50%;
                top: 4%;
                width: 100px;
            }
        }
       
        .desktop-caption{
            h6{
                font-size: 45px;
                text-align: left;
                @media(max-height: 800px){
                    font-size: 45px;
                }
            }
            p{
                font-family: Kollektif;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: 0.07em;
                text-align: left;
                width: 100%;
                max-width: 74%;
                margin: 0px;
                padding-top: 30px;
                min-height: 190px;
                @media(max-height: 800px){
                    font-size: 16px;
                    line-height: 25px;
                    min-height: 160px;
                }
            }
        }
        .inner-container{
            height: 100%;
            display: flex;
            .desktop-caption{
                height: 100%;
                display: flex;
                align-items: center;
                width: 35%;
                height: 100%;
                @media(max-height: 800px){
                    width: 40%;
                }
                .bottom-arrow-div{
                    img{
                        width: 40px;
                        margin-top: 40px;
                        margin-bottom: 10px;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                    }
                }
                .caption-inner{
                    position: relative;
                    width: 100%;
                    margin-top: 70px;
                    z-index: 1;
                    @media(max-height: 800px){
                        width: 94%;
                        margin-left: 70px;
                    }
                   .number_img{
                    position: absolute;
                    top: -40%;
                    left: -30%;
                    height: 60%;
                    z-index: -1;
                    @media(max-height: 800px){
                        top: -37%;
                        left: -15%;
                        height: 57%;
                    }
                   }
                }
            }
            .frame-container{
                width: 65%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media(max-height: 800px){
                    width: 60%;
                }
                .video-upper-container{
                    width: 100%;
                    height: 480px;
                    @media(max-height : 800px){
                        height: 350px;
                    }

                    .video-container{
                        width: 95%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        position: relative;
                        &:before{
                            content: "";
                            width: 102%;
                            height: 104%;
                            background: transparent;
                            border: 3px solid white;
                            position: absolute;
                            right: 0%;
                            top: -14%;
                            z-index: 0;
                        }
                        video{
                            width: 90%;
                            height: 87%;
                            object-fit: cover;
                            @media(max-height : 800px){
                                height: 87%;
                            }
                        }
                        .frame{
                            position: absolute;
                            width: 105%;
                            height: 110%;
                            left: -56px;
                            top: -56px;
                            z-index: 9999;
                            @media(max-width : 770px){
                                left: -20px;
                                top: -25px;
                            }
                        }
                       
                        
                    }
                    // .video-container{
                    //     width: 95%;
                    //     height: 100%;
                    //     // margin-left: 30px;
                    //     display: flex;
                    //     justify-content: flex-start;
                    //     position: relative;
                    //     &:before{
                    //         content: "";
                    //         width: 100%;
                    //         height: 104%;
                    //         background: transparent;
                    //         border: 3px solid white;
                    //         position: absolute;
                    //         top: -6%;
                    //         right: 0%;
                    //         z-index: 0;
                    //         @media(max-height : 800px){
                    //             right: 0%;
                    //             top: -7%;
                    //         }
                    //     }
                    //     video{
                    //         width: 95%;
                    //         height: 100%;
                    //         object-fit: cover;
                    //     }
                    //     .frame{
                    //         position: absolute;
                    //         width: 100%;
                    //         height: 110%;
                    //         left: -15px;
                    //         top: -16px;
                    //         z-index: 999;
                    //     }
                       
                        
                    // }
                }
              
            }
        }
    }

    .develop-land-mobile{
        background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen7/Back_Arrow.png');
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 50% 100%;
        background-position: -70% 100%;
        position: relative;
        .number{
            position: absolute;
            left: 50%;
            top: 5%;
            width: 130px;
            @media(max-height: 800px){
                left: 50%;
                top: 6%;
                width: 100px;
            }
        }
       
        .mobile-caption{
            h6{
                font-size: 26px;
            }
            .Typewriter{
                font-family: Kollektif;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.07em;
                text-align: center;
                width: 100%;
                max-width: 74%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                min-height: 80px;
                font-size: 16px;
                line-height: 25px;
            }
        }
        .inner-container{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .mobile-caption{
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 70px;
                .caption-inner{
                    position: relative;
                    width: 100%;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 50px;
                   .number_img{
                    width: 100px;
                   }
                   .jack-image{
                    z-index: -1;
                   }
                   .mobile-caption-message{
                     text-align: center;
                     width: 80%;
                    }
                }
            }
            .frame-container{
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .video-upper-container{
                    width: 100%;
                    height: 240px;
                    .video-container{
                        width: 95%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-start;
                        position: relative;
                        &:before{
                            content: "";
                            width: 102%;
                            height: 104%;
                            background: transparent;
                            border: 3px solid white;
                            position: absolute;
                            right: 0%;
                            top: -14%;
                            z-index: 0;
                        }
                        video{
                            width: 90%;
                            height: 87%;
                            object-fit: cover;
                            @media(max-height : 800px){
                                height: 87%;
                            }
                        }
                        .frame{
                            position: absolute;
                            width: 105%;
                            height: 110%;
                            left: -56px;
                            top: -56px;
                            z-index: 9999;
                            @media(max-width : 770px){
                                left: -20px;
                                top: -25px;
                            }
                        }
                       
                        
                    }
                }
              
            }
            .bottom-arrow-div{
                img{
                    width: 40px;
                    position: absolute;
                    bottom: -70px;
                    z-index: 99;
                    right: 20%;
                }
            }
        }
    }
}