
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: Poppins-regular;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}


@font-face {
  font-family: Blackpast;
  src: url('./assets/fonts/Blackpast.ttf') format("truetype");
}
@font-face {
  font-family: Kollektif;
  src: url('./assets/fonts/Kollektif.ttf');
}