.grow-your-kingdom-section{
    background: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen3/Growyourkingdom.jpg');
    background-repeat: no-repeat;
    position: relative;
    color: white;
    height: calc(100vh);
    scroll-snap-align: end;
    background-size: cover;
    @media (max-width:576px){
         height: calc(100vh);
        padding-top: 80px;
        background: url('../../assets/Grow Kingdom.svg');
        background-repeat: no-repeat;
    }   
    .grow-your-kingdom-caption{
        padding-top: 30px;
        p{
            width: 100%;
            max-width: 60%;
            margin: 0 auto;
            font-family: Kollektif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: 0.07em;
            padding-top: 30px;
            @media (max-width: 576px){
                font-size: 14px;
                line-height: 20px;
                max-width: 100%;
                padding: 10px;
            
            }
            @media (max-width: 767px){
                max-width: 80%;
            
            }
            @media (max-height : 752px)  and (min-width: 767px){
                font-size: 16px;
                padding-top: 0px;
            }
        }
    }
    .gif-wrapper{
        @media (max-width:450px){
            display: flex;
            align-items: center;
            img{
               @media (max-height:600px){
                bottom: 0px !important;
               }
               @media (min-height:601px) and (max-height:800px){
                bottom: 70px !important;
               }
               @media (min-height:801px) and (max-height:1000px){
                bottom: 110px !important;
               }
            }
        }
    img{
        position: absolute; 
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        bottom: 120px;
        display: block;
        height: 60%;
        @media (max-width:767px){
            width: 90%;
        }
        @media (max-width:450px){
            width: 90%;
            height: auto;
            top: 375px;
        }
        }
    }
}
