.footer-section{
    background-color: #000000;
    height: 15%;
    display: flex;
    align-items: center;
    padding: 6px 0px;
    .logo-container{
        cursor: pointer;
        img{
          
            width: 100px;
            height: 80px;
        }

        @media(max-height: 600px){
            visibility: inherit;
        }

    }
    .footer-wrapper{
        justify-content: space-between;
        align-items: center;
    }
    .social-icons-container{
        .list-group-item{
            background: transparent;
        }
        @media(max-width: 576px){

            display: none;
    }
    }


}