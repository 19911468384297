.roadmap-section{
    position: relative;
    color: white !important;
    // background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen11/roadmap_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 101%;
    scroll-snap-align: end;
    padding-top: 30px;
    height: auto;
          
    .section-heading{
        padding-top: 70px;
    }
    // @media (max-width: 767px){
    //     padding-top: 10px;
    // }
    // h6{
    //     // @media (max-width: 767px){
    //     //     margin-bottom: 0px;
    //     // }
    // }
    .road-map-image{
        width: 100%;
    }  
}


.roadmap-line-wrapper{
    margin-top: 30px;
    height: 50px;
    border-top: 4px solid #83F8F7;
    position: relative;

    // @media (max-width: 767px){
    //     border-top: none;
    //     height: auto;
    //     border-right: 4px solid #83F8F7;
    //     width: 20px;
    //     margin-left: 30px;
    // }

}
.row-inner-wrapper{
    padding-top: 70px;
    // @media (max-width: 767px){
    //     display: flex;
    // }
}
// .roadmap-wrapper{
//     // @media (max-width: 767px){
//     //     display: block !important;
//     //     margin-left: 12%;
//     //     padding-right: 10px;
//     // }
  
// }
.row-inner-wrapper, .roadmap-inner-wrapper-mob{
    padding-bottom: 20px;
    .box-wrapper{
        padding-left: 80px;
        padding-right: 15px;
        position: relative;
        .line-wrapper-mob{
            position: absolute;
            border-left: 4px solid #83F8F7;
            height: 100%;
            left: 36px;
            top: -4px;
        }
        .box{
            margin-top: 60px;
            margin-bottom: 40px;
            width: 100%;
            height: auto;
            margin-left: 0px;
            margin-right: 0px;
            .pointer{
                position: absolute;
                width: 75px;
                left: -81px;
                top: -7px;
            }
        }
        .box1{
            margin-top: 60px;
            margin-bottom: 40px;
            width: 100%;
            height: auto;
            margin-left: 0px;
            margin-right: 0px;
            .pointer{
                position: absolute;
                width: 75px;
                left: -81px;
                top: -7px;
            }
        }
     
    }
    .react-horizontal-scrolling-menu--scroll-container {
        padding-top: 120px;
    }
    .box{
        border: 1px solid #7AEEFF;
        width: 440px;
        height: auto;
        margin: 20px;
        padding: 20px;
        font-family: 'Kollektif';
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.04em;
        background-color: #01014C;
        
        .box-line{
            position: absolute;
            width: 110%;
            top: -102px;
            border-top: 4px solid #83F8F7;
            left: 0px;
            .pointer{
                position: absolute;
                top: -30px;
                left: -20px;
                width: 90px;
            }
            
        }
        
       
        
        @media (max-width: 1280px){
            font-size: 14px;
        }
        // @media (max-width: 767px){
        //     margin-top: 60px;
        //     margin-bottom: 40px;
        //     width: 100%;
        //     height: auto;
        //     margin-left: 0px;
        //     margin-right: 0px;
        // }
        .left-arrow{
            right: 0px;
            top: 0px;
        }
        .right-arrow{
            left: 0px;
            top: 0px;
        }
        .bottom-img{
            bottom: 0px;
            left: 0px;
        }
        .line-img{
            right: 5px;
            bottom: 5px;
        }
      
        .pointer-title{
            top: -38px;
            left: 0px;
            font-family: 'Blackpast';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 15px;
            @media (max-width: 1280px){
                font-size: 20px;
            }
            // @media (max-width: 767px){
            //     font-size: 20px;
            //     top: -35px;
            //     margin: 0px;
            // }
        }
    }
    .box1{
        border: 1px solid #7AEEFF;
        width: 440px;
        height: auto;
        margin: 20px;
        padding: 20px;
        font-family: 'Kollektif';
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.04em;
        background-color: #01014C;
        
        .box-line{
            position: absolute;
            width: 110%;
            top: -102px;
            border-top: 4px solid #83F8F7;
            left: 0px;
            .pointer{
                position: absolute;
                top: -30px;
                left: -20px;
                width: 90px;
            }
            
        }
        
       
        
        @media (max-width: 1280px){
            font-size: 14px;
        }
        // @media (max-width: 767px){
        //     margin-top: 60px;
        //     margin-bottom: 40px;
        //     width: 100%;
        //     height: auto;
        //     margin-left: 0px;
        //     margin-right: 0px;
        // }
        .left-arrow{
            right: 0px;
            top: 0px;
        }
        .right-arrow{
            left: 0px;
            top: 0px;
        }
        .bottom-img{
            bottom: 0px;
            left: 0px;
        }
        .line-img{
            right: 5px;
            bottom: 5px;
        }
      
        .pointer-title{
            top: -38px;
            left: 0px;
            font-family: 'Blackpast';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 15px;
            @media (max-width: 1280px){
                font-size: 20px;
            }
            // @media (max-width: 767px){
            //     font-size: 20px;
            //     top: -35px;
            //     margin: 0px;
            // }
        }
    }
    .arrow-button{
        background: transparent;
        border: none;
        position: absolute;
        top : 180px;
        cursor: pointer;
        width: 5%;
        @media (max-width: 1024px){
            width: 10%;
        }
        &:hover, &:active, &:focus{
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
        }
        img{
        width: 100%;
        }
    }
    .left{
        left: 25%
    }
    .right{
        right: 25%;
    }
    .large-box{
        padding-bottom: 10px !important;
    }
}

.react-horizontal-scrolling-menu--scroll-container {
    .react-horizontal-scrolling-menu--item:first-child{
        padding-left: 140px;
            .box-line{
                left: -158px;
                width: 146%;
                .pointer{
                    left: 120px;
                }
            }
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar{
    display: none;
}

.mark-checked{
    display: flex;
    align-items: center;
    .checkmark {
        display:inline-block;
        width: 22px;
        height:22px;
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
        transform: rotate(45deg);
        margin-left: 10px;
    }
    
    .checkmark_circle {
        position: absolute;
        width:22px;
        height:22px;
        background-color: green;
        border-radius:11px;
        left:0;
        top:0;
    }
    
    .checkmark_stem {
        position: absolute;
        width:3px;
        height:9px;
        background-color:#fff;
        left:11px;
        top:6px;
    }
    
    .checkmark_kick {
        position: absolute;
        width:3px;
        height:3px;
        background-color:#fff;
        left:8px;
        top:12px;
    }
}