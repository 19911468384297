.conquer-metaverse-section{
    height: calc(100vh);
    position: relative;
    color: white;
    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen4/conquere-metaverse.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    scroll-snap-align: end;
    padding-top: 20px;
    .gif-container{
        height: calc(100vh - 200px);
        width: 75%;
        position: relative;
        padding-bottom: 20px;
        @media (max-width: 525px){
           width: 100%;
        }
        img.gif{
            border: 2px solid #12DEF2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-height : 752px)  and (min-width: 767px) and (max-width : 1280px){
                object-fit: fill;
                width: 85%;
                display: block;
                margin: 10px auto;
            }
        }
        .border-right{
            position: absolute;
            top: 2px;
            right: 13px;
            width: 4%;
            @media (max-height : 752px)  and (min-width: 767px) and (max-width : 1280px){
                right: 82px;
             }
        }
        .border-left{
            position: absolute;
            top: 2px;
            left: 13px;
            width: 4%;
            @media (max-height : 752px)  and (min-width: 767px) and (max-width : 1280px){
               left: 82px;
            }
        }
    }
    .conquer-metaverse-caption{
        padding-top: 30px;
        padding-bottom: 20px;
        p{
            width: 100%;
            max-width: 60%;
            margin: 0 auto;
            font-family: Kollektif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: 0.07em;
            @media (max-width: 576px){
                font-size: 14px;
                line-height: 20px;
            }
            @media (max-height : 752px)  and (min-width: 767px) and (max-width : 1280px){
                font-size: 16px;
            }
        }
    }
}

