.jack-section{
    height: calc(100vh);
    scroll-snap-align: end;
    position: relative;
    color: white;
    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen2/JacksBG.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    @media (max-width : 820px) {
        background-size: cover;
        background-position: center;
    }
    &.clicked{
        .left_jack, .right_jack, .middle_jack{
            animation: fadeIn 4s;
            -webkit-animation: fadeIn 4s;
            -moz-animation: fadeIn 4s;
            -o-animation: fadeIn 4s;
            -ms-animation: fadeIn 4s;
        }
    }
    .jack-caption{
        padding-top: 30px;
        p{
            width: 100%;
            max-width: 60%;
            margin: 0 auto;
            font-family: Kollektif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            letter-spacing: 0.07em;
            @media (max-width: 767px){
                max-width: 100%;
                font-size: 14px;
                line-height: 20px;
            }
            @media (max-height : 752px)  and (min-width: 767px){
                font-size: 16px;
            }
        }
        .jack_img{
            height: 82%;
        }
        .left_jack{
            position: absolute;
            left: 17%;
            top: 33%;
            height: 40%;
            transition: none;
            
            @media (max-height: 800px){
                position: absolute;
                left: 17%;
                top: 38%;
                height: 35%;
            }

            @media (max-width: 1024px){
                position: absolute;
                left: 9%;
                top: 42%;
                height: 30%;
            }
            &.doctor_left{
                left: 18%;
            }
        }
        .right_jack{
            position: absolute;
            right: 16%;
            top: 35%;
            height: 40%;
            transition: none;

            @media (max-height: 800px){
                top: 37%;
                height: 35%;
            }
            @media (max-width: 1024px){
                top: 42%;
                height: 30%;
                right: 10%;
            }

            
            &.pirate_right{
                right: 11%;
                @media (max-width: 1024px){
                    right: 0%;
                }
            }
            &.doctor_right{
                right: 18%;
                
                @media (max-width: 1024px){
                    right: 14%;
                }
               
            }
            &.boudica_right{
                right: 15%;
                top: 31%;
                @media (max-width: 1024px){
                    right: 10%;
                    top: 42%;
                }
                @media (max-height: 800px){
                    top: 36%;
                    right: 16%;
                }
            }
        }
        .middle_jack{
            position: absolute;
            top: 65%; left: 50%;
            transform: translate(-50%,-50%);
            height: 100%;
            transition: none;
            z-index: 99;
            @media (max-height: 800px){
                height: 85%;
            }
            @media (max-width: 1024px){
                height: 80%;
             }
           
            @media screen and (max-width: 400px), 
             screen and (max-height: 650px) {
              height: 85%;
              top: 68%;
            }
            &.boudica_gif{
                top: 60%;
                height: 90%;
                @media screen and (max-width: 400px), 
                screen and (max-height: 650px) {
                    top: 61%;
               }
            }
            &.capitalist_gif{
                top: 58%;
                left: 48%;
                @media (max-width : 1024px){
                    top: 56%;
                    left: 48%;
                    height: 100%;
                }
                @media screen and (max-width: 400px), 
                screen and (max-height: 650px) {
                    top: 60%;
                    height: 95%;
               }
            }
            &.doctor_gif{
                top: 57%;
                height: 90%;
                @media (max-width: 1024px){
                    top: 58%;
                }
                @media (max-height: 800px){
                    top: 65%;
                }
                @media screen and (max-width: 400px), 
                screen and (max-height: 650px) {
                    height: 90%;
                    top: 58%;
               }
               
            }
            
            &.ripper_gif{
                height: 95%;
                top: 60%;
                @media (max-height: 800px){
                    height: 90%;
                    top: 58%;
                }
                @media (max-width: 1024px){
                    height: 85%;
                    top: 61%;
                 }
                 @media screen and (max-width: 400px), 
                 screen and (max-height: 650px) {
                    height: 92%;
                    top: 60%;
                }
              
            }
            &.pirate_gif{
                height: 85%;
                top: 61%;
                @media (max-width: 1024px){
                    height: 94%;
                    top: 57%;
                }
                @media (max-height: 800px){
                    // height: 85%;
                    top : 65%;
                }
              
                @media screen and (max-width: 400px), 
                    screen and (max-height: 650px) {
                        height: 85%;
                        top: 61%;
                }
            }
        }
    }

    .dots-container{
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: space-around;
        margin : 0 auto;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width : 768px) {
            bottom: 0px;
        }
        .dots{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: white;
            margin: 5px;
            &.selected{
                background-color: #00DEFF;
            }
        }
    }
    .arrow-div{
        .right{
            right: 30%;
            @media (max-width : 768px) {
                right: 6%;
            }
        }
        .left{
            left: 30%;
            @media (max-width : 768px) {
                left: 6%;
            }
        }
        img{
            position: absolute;
            bottom: 6%;
            width: 8%;
            z-index: 999999;
            cursor: pointer;
            @media (max-width : 768px) {
               width: 15%;
            }
        }
    }
}

.cursor-pointer{
    cursor: pointer;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }