.teams-section{
    height: calc(100vh);
    position: relative;
    color: white;
    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen12/team_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    scroll-snap-align: end;
    @media (max-width: 450px){
        height: auto;
    }
    .scrollable{
        @media (max-width:525px){
            &::-webkit-scrollbar{
              display: none;
              }
          }
    }
   
    .section-heading{
        padding-top: 30px;
        // padding-bottom: 20px;
    }
    h6.advisors{
        font-size: 35px;
        margin-top: 35px;
        padding-top: 0px;
        @media (max-height: 750px){
            margin-top: 20px;
          }
        @media (max-width:525px){
            margin-top: 0px;
        }
       
    }
    .team-row{
        height: 35%;
        justify-content: center;
        @media (max-width:525px){
            justify-content: center;
            height: auto;
        }
        .member-photo-div{
            position: relative;
            margin: 0px 30px;
            margin-bottom: 20px;
            margin-top: 10px;
            // &.designer{
            //     width: 100%;
            //     left: -8%;
            // }
            @media (max-width:767px){
                margin-left: 10px;
                margin-right: 10px;
            }
            @media (max-height: 750px){
                margin-top: 5px;
            }
            .team-caption{
                // margin-top: 20%;
                p{
                    margin: 0px;
                }
                p.title{
                    font-weight: 300;
                    font-size: 15px;
                    width: auto;
                    @media(max-width: 1280px){
                        font-size: 11px;
                    }
                }
            }
          
            .member-img{
                height: 100%;
                @media (max-width:525px){
                    height: auto;
                    width: 80%;
                    margin: 0 auto;
                    display: block;
                }
            }
            .linkdln-img{
                position: absolute;
                bottom: 26%;
                left: 26%;
                height: 31%;
                @media (max-width:525px){
                    bottom: 20%;
                    left: 32%;
                    height: 30%;
                }
            }
            .circle-border{
                top: -5%;
                right: -7%;
                width: 115%;
                position: absolute;
                @media (max-width:525px){
                    width: 90%;
                    right: 5%;
                    top: -5%
                }
            }
        }
    }
}
// .teams-section::-webkit-scrollbar{
//     display: none;
// }