.contact-us-section{
    height: calc(100vh);
    position: relative;
    color: white;
    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen13/ContactUSBG.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    scroll-snap-align: end;
    @media (max-width: 767px){
        background-size: auto 100%;
        height: auto;
    }
    @media (max-width:820px){
        padding-top: 100px;
    }
    .contact-us-caption-col{
        position: relative;
        display: table;
        margin-top: 20px;
        @media (max-width:820px){
            width: 100%;
        }
        .contact-us-caption{
            padding-left: 150px;
            display: table-cell;
            vertical-align: middle;
                @media (max-width:820px){
                    width: 80%;
                    padding-left: 0px;
                }
                @media (max-width: 300px){
                    width: 100%;
                    padding: 0px;
                }
                h6{
                    text-align: left;
                    @media (max-width:820px){
                        text-align: center;
                    }
                }
            p{
                font-family: 'Kollektif';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                text-align: left;
                letter-spacing: 0.07em;
                margin-top: 50px;
                    @media (max-width:820px){
                        text-align: center;
                    }
                    @media (max-width: 1280px){
                        font-size: 20px;
                      }
                    @media (max-width: 576px){
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
        }
    }

    .contact-us-form-col{
        position: relative;
        display: table;
        @media (max-width:820px){
            width: 100%;
        }
        
        
        .form-box-upper-wrapper{
            display: table-cell;
            vertical-align: middle;
        }
        .form-box{
            background: linear-gradient(180deg, #6B01DA 0%, rgba(72, 62, 235, 0) 100%);
            width: 100%;
            max-width: 70%;
            margin: 0 auto;
            padding: 38px 28px;
            border-right: 10px solid;
            border-left: 10px solid;
            border-top: 10px solid;
            border-image-slice: 1;
            border-width: 5px;
            border-image-source: linear-gradient(180deg, #00DEFF 0%, rgba(72, 62, 235, 0) 100%);
            margin-top: 100px;
            
          
            @media (max-width:1100px){
                max-width: 100%;
            }
          
            
            @media (max-width:820px){
                max-width: 80%;
                margin-top : 0px;
            }
            @media (max-width: 300px){
                max-width: 100%;
            }
            @media (max-height : 752px)  and (min-width: 767px){
                max-width: 80%;
                margin-top: 0px;
                padding-top: 30px;
                padding-bottom: 15px;
            }
            form{
                .input-field-contact-us{
                    background-image: url('https://d14utwz37q7qa4.cloudfront.net/assets/images/screen13/input_bg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 50px;
                    color: white;
                    border: none;
                    background-color : transparent;
                    &.text-area{
                        height: 110px;
                    }
                }
                .input-field-contact-us::placeholder{
                    font-size: 12px;
                }
                .send-btn{
                    padding: 10px;
                    width: 50%;
                    height: 45px;
                    background: #00DEFF;
                    box-shadow: 0px 12px 23px rgba(122, 238, 255, 0.29);
                    border-radius: 10px;
                    font-family: 'Kollektif';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 109.5%;
                    text-align: center;
                    letter-spacing: 0.25em;
                    text-transform: uppercase;
                    color: #000000;
                    margin: 0 auto;
                    display: block;
                    margin-top: 20px;
                    // @media (max-height : 752px)  and (min-width: 767px){
                    //     padding: 10px;
                    //     font-size: 18px;
                    //     height: 45px;
                    // }
                    // @media (max-width: 767px){
                    //     padding: 10px;
                    //     font-size: 18px;
                    //     height: 45px;
                    // }
                }
            }
        }
    }
    textarea {
        resize: none;
    }
    textarea::-webkit-scrollbar{
        display: none;
    }
}

.form-box::-webkit-scrollbar{
    display: none;
}