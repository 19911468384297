html{
  scroll-behavior: smooth;
}

.dark-blue-bg{
  background-color: black !important;
}
.color-white{
  color: white;
}
.w-100{
  width: 100%;
}
.side-banner-div{
  position: fixed;
  z-index: 99999999999999999;
  top: 16vh;
  width: 360px;
  margin-right: -2%;
  left: 93%;
  animation: none;
 
  
  @media (min-width: 1700px){
    left: 93%;
    margin-right: -2%;
  //  right: 2%;
  }
  @media (max-width: 1281px){
    width: 300px;
    left: 93%;
    margin-right: -2%;
  }
  @media (max-width: 450px){
    width: 200px;
    left: 82%;
  }
  
  .content{
    position: absolute;
    top: 32%;
    left: 8%;
    color: white;
    width: 100%;
    text-align: center;
    a{
      color: #000000 !important;
      text-decoration: none !important;
    }
    @media (max-width: 1280px){
      top: 36%;
    }
    @media (max-width: 500px){
      top: 33%
    }
    p{
      font-family: Kollektif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      @media (max-width: 1280px){
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 20px;
      }
      @media (max-width: 500px){
        font-size: 10px;
        margin-bottom: 0px;
        line-height: 20px;
      }
    }
    button{
      font-family: Kollektif;
      background: #12DEF2;
      border-radius: 37px;
      padding: 10px 32px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      @media (max-width: 1280px){
        font-size: 12px;
        padding: 5px 22px;
      }
      @media (max-width: 500px){
        font-size: 10px;
        padding: 5px 22px;
      }
    }
  }
  img{
    width: 100%;
  }
}
.side-banner-div:hover{
  margin-right: -2% !important;
  left: calc(100% - 360px) !important;
  @media (max-width: 1280px){
    left: calc(100% - 250px) !important;
  }
  @media (max-width: 450px){
    left: 56% !important;
  }
}

@keyframes changewidth {
  0% {
    left: 82%;
  }
  30% {
    left: calc(100% - 185px);
  }

  70% {
    left: calc(100% - 185px);
  }

  100% {
    left: 82%;
  }
}

@keyframes changewidthForTab {
  0% {
    left: 96%;
  }
  30% {
    left: calc(100% - 250px);
  }

  70% {
    left: calc(100% - 250px);
  }

  100% {
    left: 96%;
  }
}

@keyframes changewidthForLaptop {
  0% {
    left: 93%;
  }
  30% {
    left: calc(100% - 300px);
  }

  70% {
    left: calc(100% - 300px);
  }

  100% {
    left: 93%;
  }
}

@keyframes changewidthForMob {
  0% {
    margin-left: 0%;
  }
  30% {
    margin-left: 0%;
  }

  70% {
    margin-left: 50%;
  }

  100% {
    margin-right: 50%;
  }
}

body{
  overflow: hidden;
  @media (max-width: 450px){
    overflow: auto;
  }
}


.section-heading{
  font-family: 'Blackpast';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 109.8%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  color: #00DEFF;
  text-shadow: 0px 9px 20px rgba(122, 238, 255, 0.45);
  @media (max-width: 1280px){
    font-size: 40px;
  }
  @media (max-height: 752px){
    font-size: 35px;
  }
  @media (max-width: 576px){
    font-size: 35px;
  }
}
.scroll-container {
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 450px){
    overflow-y: auto;
    height: auto;
    scroll-snap-type: none;
    margin-top: 0px;
  }
}
.scroll-container::-webkit-scrollbar{
  display: none !important;
}

@media(min-width: 1400px){
  // .container{
  //   max-width: 1450px !important;
  // }
}

.idle{
  .side-banner-div-animation{
    @media(max-width: 450px){
    left: 82%;
    animation-name: changewidth;
    animation-duration: 3s;
    animation-direction: alternate;
    }
    @media (max-width: 1280px) and (min-width: 450px){
      left: 90%;
      animation-name: changewidthForTab;
      animation-duration: 3s;
      animation-direction: alternate;
    }
    @media (min-width : 1280px){
      left: 90%;
      animation-name: changewidthForLaptop;
      animation-duration: 3s;
      animation-direction: alternate;
    }
  }
}
